<template>
  <div>
    <feather-icon
      title="View Order Details"
      icon="EyeIcon"
      svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
      @click="displayRecord"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import _ from "lodash";
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';
export default {
  name: "CellRendererActions",
  components: {
    vSelect,
  },
  data() {
    return {
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    displayRecord() {
      this.$router.push({
        name: "orderdetails-view",
        params: {
          applicationId: this.$route.params.applicationId,
          orderId: this.params.data.usageOrderId,
        },
      })
      .catch((error) => console.error(error));
    },
  },
};
</script>

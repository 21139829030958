<template>
  <travio-center-container pageTitle="New Supplier Integration">
    <vx-card>
      <div class="flex flex-wrap items-center mb-4">
        <vs-avatar 
          :src="supplierLogo"
          :text="supplierName" 
          class="supplier-logo-avatar mr-2" 
          size="60px"
        />
        <div class="ml-2"> 
          <h2 class="supplier-name-heading">{{ supplierName }}</h2>
          <p class="text-muted text-sm mt-1">{{ productType }}  &#8226;  {{ apiType }}</p>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Description*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.description" v-validate="'required|max:50'" name="Description" />
          <span class="text-danger text-sm">{{ errors.first('Description') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6" v-if="usernameLabel">
        <div class="vx-col sm:w-1/5 w-full">
          <span>{{usernameLabel}}</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.username" v-validate="'max:1250'" name="Username" />
          <span class="text-danger text-sm">{{ errors.first('Username') }}</span>
        </div>
      </div>

     <div class="vx-row mb-6" v-if="passwordLabel">
        <div class="vx-col sm:w-1/5 w-full">
          <span>{{passwordLabel}}</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.password" v-validate="'max:1250'" name="Password" />
          <span class="text-danger text-sm">{{ errors.first('Password') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Commision</span><br/><span>Level %</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full mt-3" 
            v-model.number="form.commission" type="number" step="0.1" name="Commision Level" @input="setEmptyToNull" :disabled="readOnly"
            v-validate="{ rules: { required: false, min_value: 1, max_value: 100, integer: false},}"/>
          <span class="text-danger text-sm">{{ errors.first('Commision Level') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <div class="flex flex-wrap justify-start">
            <vs-switch v-model="enableEndpoint">Test</vs-switch>
            <div class="ml-4">Specify Custom endpoint (not recommended)</div>
          </div>
        </div>
      </div>
      
      <div class="vx-row mb-6" v-if="enableEndpoint">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Endpoint*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.endpoint" v-validate="'required|max:500'" name="Endpoint" />
          <span class="text-danger text-sm">{{ errors.first('Endpoint') }}</span>
        </div>
      </div>
      
      <div class="vx-row mb-6" v-if="isActiveAppSharingSuppliers">
        <div class="vx-col w-full">
          <div class="flex flex-wrap justify-start">
            <vs-switch v-model="form.isShared"></vs-switch>
            <div class="ml-4">Is Shared</div>
          </div>
        </div>
      </div>

      <div class="mt-10">
        <h5 style="color:#636363">Additional Settings</h5>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="settingsData"
        :gridOptions="gridOptions"
        :rowSelection="rowSelection"
        :pagination="false"
        :suppressPaginationPanel="true"
        :context="context"
        :rowClassRules="rowClassRules"
        :style="{ height:'500px' }"
        :enableBrowserTooltips="true"
      >
      </ag-grid-vue>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click='onSubmit' class="mt-4">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'
import {AgGridVue} from "ag-grid-vue";
import 'ag-grid-community/dist/styles/ag-grid.css';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererIntegrationSettingsValue from './cell-renderers/CellRendererIntegrationSettingsValue.vue'
import Vue from 'vue'

export default {
  components: {
    vSelect,
    AgGridVue,
    CellRendererIntegrationSettingsValue
  },
  data () {
    return {
      form: new Form({
        description: '',
        username: null,
        password: null,
        endpoint: null,
        isShared: false,
        commission: null
      }),
      usernameLabel: null,
      passwordLabel: null,
      settings: [],
      supplierLogo: '',
      supplierName: '',
      productType: '',
      apiType: '',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: { CellRendererIntegrationSettingsValue },
      context: null,
      rowClassRules: null,
      enableEndpoint: false,
      isActiveAppSharingSuppliers: false
    }
  },
  props: {
    supplierId: { required: true },
    applicationId: { required: true }
  },
  computed: {
    settingsData () {
      return this.settings
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'threadId', hide: true },
      { field: 'settingOptions', hide: true },
      { field: 'settingIsSet', hide: true },
      { field: 'settingOptionsMultiple', hide: true },
      { field: 'isDirty', hide: true },
      { field: 'isNewSettings', hide: true, field: 'isNewSettings',},
      { field: 'settingValue', hide: true },
      { field: 'settingName', hide: true },
      { headerName: 'Setting Description', field: 'settingDescription', sortable: true, suppressSizeToFit: false, width: 300, autoHeight: true, tooltipField: 'settingDescription' },
      { headerName: 'Setting Value', 
        sortable: true,
        suppressSizeToFit: false,
        width: 450,
        autoHeight: true,
        cellRendererFramework: Vue.extend(CellRendererIntegrationSettingsValue) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      autoHeight: true,
      resizable: true,
      suppressMenu: true
    }
    this.rowSelection = 'single';
    this.rowClassRules = {
      'dirty-style': 'data.isDirty',
    };
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
  },
  created () {
      //Check if user has acccess to the company
      const hasAccess = this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)
      if(!hasAccess) {
        this.$router.push('/error-404')
        return
      }

      this.$vs.loading()
      this.$http.get(`api/supplierIntegrations/apps/${this.applicationId}/supplier/${this.supplierId}/details`)
      .then(response => {
        this.usernameLabel = response.data.usernameLabel
        this.passwordLabel = response.data.passwordLabel
        this.supplierLogo = response.data.supplierLogo
        this.supplierName = response.data.supplierName
        this.productType = response.data.productType
        this.apiType = response.data.apiType
        this.isActiveAppSharingSuppliers = response.data.isAppSharingSuppliers
        this.settings = response.data.settings && response.data.settings.map(s => ({ ...s, isNewSettings: true }))
      })
      .catch(err => console.error(err) )
      .finally(() => this.$vs.loading.close())
  },
  methods: {
    onSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.$http.post(
            `api/supplierintegrations/apps/${this.applicationId}/supplier/${this.supplierId}/integrations`,
            { settings: this.settings, ...this.form })
            .then(response => {
              this.$_notifySuccess('Supplier integration successfully saved');
              this.$router.push({name: 'supplier-integrations-details', 
                params: { supplierId: this.supplierId, applicationId: this.applicationId }
              })
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleCancel () {
      this.$router.push({ name: 'supplier-integrations-details', params: { applicationId: this.applicationId, supplierId: this.supplierId}})
    },
    onDirty () {
      //this is to force refresh the dirty styling
      this.settings = [...this.settings]
    },
    setEmptyToNull (val) {
      if (val === '') {
        this.form.commission = null
      }
    },
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }
  /* .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
    background: #787879;
    border: none;
    color: #664f39;
    text-transform: lowercase;
    font-variant: small-caps;
  }

  .style-chooser .vs__clear,
  .style-chooser .vs__open-indicator {
    fill: #394066;
  } */

  .dirty-style:not(.vs-button) {
    color: red !important
  }
</style>

<template>
  <div class="vx-row mb-2">
    <dl class="vx-col w-2/5 font-semibold">{{ label + ':'}}</dl>
    <dd v-if="!html" class="vx-col w-3/5">{{ value }}</dd>
    <span v-if="html" class="vx-col w-3/5" v-html="html"></span>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, required: true},
    value: { required: false },
    html: { type: String }
  }
}
</script>

<style>

</style>
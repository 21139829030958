<template>
  <travio-center-container grid-width='2/3' :pageTitle="pageTitle">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="row">
          <div class="vx-col w-2/3">
            <travelify-display-field label="Credit Limit" :value='formattedAmount' />
          </div>
          <div class="vx-col w-2/3">
            <travelify-display-field label="Credit Remaining" :value='formattedcreditRemaining' />
          </div>
        </div>

        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="creditUsages"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @grid-size-changed="onGridSizeChanged"
        >
        </ag-grid-vue>
        <div class="flex flex-wrap justify-end">
          <vs-button type="border" @click="manageCreditLimit" class="ml-4 sm:mb-0 mb-2">
            Back to Manage Credit Limit
          </vs-button>
        </div>
      </vx-card>

    </div>
  </travio-center-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererCreditUsageActions from "./cell-renderers/CellRendererCreditUsageActions.vue";

import Vue from "vue";
import { format } from 'date-fns'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';
import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'
export default {
  components: {
    AgGridVue,
    CellRendererCreditUsageActions,
    TravelifyDisplayField
  },
  props: {
  },
  data() {
    return {      
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null, 
      rowSelection: null,
      cellRendererComponents: {
        CellRendererCreditUsageActions
      },
      searchQuery: "",
      showDeletePriceRulePopup: false,
      selectedReplacementPriceRule: {},
      creditLimitAmount: null,
      creditRemaining: null,
      creditLimitCurrency: null,
      creditUsages: [],
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    pageTitle () {
      return `Credit Usage for Credit Id ${this.$route.params.creditId}`; 
    },
    formattedAmount () {
      return this.currencyFormatter(parseFloat(this.creditLimitAmount), this.creditLimitCurrency) 
    },
    formattedcreditRemaining () {
        return this.currencyFormatter(parseFloat(this.creditRemaining), this.creditLimitCurrency)
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    preferedDateFormat() {      
      return this.$store.state.accountStore.generalSettings.dateFormat;
    },
    titleOptions () {
      return this.$store.state.lookups.titleOptions
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    
  },
  created() {
    
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.$route.params.applicationId)) ) {
      this.$router.push('/error-404')
    }

    this.$vs.loading()
    this.$http.get(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}/credit/${this.$route.params.creditId}/usages`)
    .then(response => {
        this.creditUsages = response.data.creditUsages
        this.creditLimitAmount = response.data.amount
        this.creditLimitCurrency = response.data.currencyCode
        this.creditRemaining = response.data.creditRemaining
    })
    .catch(error => console.error(error))
    .finally(() => this.$vs.loading.close())  

  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs = [
      { headerName: '', width: 60,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererCreditUsageActions), cellStyle: { textAlign: 'center' } }, 
      { 
        headerName: "Order Id",
        width: 120, 
        field: "usageOrderId",
        suppressSizeToFit: true, 
      },
      {
        headerName: "Amount",
        width:140,
        sortable: true,        
        suppressSizeToFit: true,
        valueGetter: params => params.data.usageAmount && this.currencyFormatter(params.data.usageAmount, this.creditLimitCurrency),
      }, 
      {
        headerName: "User Name",        
        valueGetter : this.getFullName,
        sortable: true,
        width: 150,
        suppressSizeToFit: false,
      },
      { 
        headerName: "User Email",
        width: 180, 
        field: "customerEmail",
        suppressSizeToFit: false, 
      },
      { 
        headerName: 'Usage Date',
        field: 'createdDate',
        sortable: true,
        width: 180, 
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      }
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    currencyFormatter(amount, currencyCode) {
      var amtDecimal = (amount && amount.toFixed(2)) || '0.00';
      return `${currencyCode || ""} ${amtDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
    activeFormatter(params){
      return params.data.isActive == 1 ? 'Active' : 'Inactive';
    },  
    addCreditLimit() {
      this.$router.push({
        name: "company-credit-limit-editor-add",
        params: { applicationId: this.applicationId },
        params: { companyId: this.companyId },
      });
    },
    manageCompanies () {
      this.$router.push({
        name: "company-list",
        params: { applicationId: this.applicationId },
      });
    },
    manageCreditLimit () {
      this.$router.push({
        name: 'company-credit-limit',
        params: {
          applicationId: this.$route.params.applicationId,
          companyId: this.$route.params.companyId,
          creditId: this.$route.params.creditId
        },
      })
      .catch((error) => console.error(error));
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onGridSizeChanged(params) {
      if (params.clientWidth > 500) {
        this.gridApi.sizeColumnsToFit();
      } else {
        this.columnDefs.forEach(x => {
          x.width = 300;
        });
        this.gridApi.setColumnDefs(this.columnDefs);
      }
    },
    getFullName(params){
      let fName = params.data.customerFirstName == null ? "" : params.data.customerFirstName;
      let lName = params.data.customerLastName == null ? "" : params.data.customerLastName;
      let fullNameNoTitle = fName + " " +  lName;
      return fullNameNoTitle
    }
  },
};
</script>

<style>
</style>
